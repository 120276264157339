<template>
  <div>
    <h1
        class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
        style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
      >
      <h6 class="h2 text-white d-inline-block mb-0"> Pengumuman</h6>
    </h1>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7" >
        </b-col>
      </b-row>
    </base-header>

    <b-container v-if="isLoading">
        <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
    </b-container>

    <b-container fluid class="mt--6" v-if="!isLoading">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <b-row>
                <b-col lg="12" cols="12" style="display: flex; justify-content: flex-end">
                  <base-button type="primary" @click="addAnouncement()">+ Tambah Pengumuman</base-button>
                </b-col>
              </b-row>
            </template>

           <section id="mytable">
            <el-table
              :data="listData"
              row-key="id"
              header-row-class-name="thead-light"
            >

              <el-table-column label="No" type="index" width="70" />


              

              <el-table-column min-width="160px"  label="Tanggal Terbit">
                <div slot-scope="action">
                  <!-- {{ formatDate()  }} --> {{ action.row.publish_date }}
                </div>
              </el-table-column>

              <el-table-column min-width="550px" label="Keterangan">
                <div slot-scope="action">
                  <span v-html="action.row.content"> </span>
                </div>
              </el-table-column>


              <el-table-column min-width="300px"  label="AKSI">
                <div slot-scope="action">
                    <base-button
                    @click="view(action.row)"
                    type="secondary"
                    size="sm"
                    icon
                    >
                    RINCIAN
                    </base-button>
                    <base-button
                    @click="handleEdit(action.row)"
                    type="warning"
                    size="sm"
                    icon
                    >
                    UBAH
                    </base-button>
                    <base-button
                    @click="handleDelete(action.row)"
                    type="danger"
                    size="sm"
                    icon
                    >
                    HAPUS
                    </base-button>
                </div>
              </el-table-column>
            </el-table>

            <div class="py-3">

                <el-select
                  class="select-primary pagination-select mr-3"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  @change="changeSizePage()"
                  >
                  <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>

                <small class="card-category">
                Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                </small>
            </div>

            <div style="display: flex;justify-content: flex-end;">
              <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @change="paginationChanged($event)"
              >
              </base-pagination>
            </div>
           </section>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

     <!-- Modal untuk Tambah pengumuman table -->
     <Modal
      :show="showFormModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showFormModal = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div>
            <div class="p-3">
              <h2 class="text-center">Tambah Pengumuman</h2>

              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Tanggal</label>
                <div class="col-lg-12">
                  <base-input class="m-0">
                      <flat-picker
                          placeholder="Tanggal"
                          :config="provideSelect.flatPickerConfig"
                          class="form-control datepicker"
                          v-model="tanggal"
                          style="background:white;"
                      />
                  </base-input>
                </div>
              </div>

              <!-- <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Title</label>
                <div class="col-lg-12">
                  <base-input
                    name="Title"
                    rules="required"
                    v-model="addTitle"
                  ></base-input>
                </div>
              </div> -->

              <label class="form-control-label">Isi Pengumuman</label>
              <html-editor v-model="addContent"></html-editor>

              <base-input
                  rules="required"
                  name="File"
              >
                  <file-input
                  accept="application/pdf"
                  ref="announcement_attachment_file"
                  @change="uploadFileBlob(file.announcement, 'announcement_attachment_file')"
                  ></file-input>
              </base-input>
              <small v-if="file.announcement.announcement_attachment_file"
                class="d-block mt-3 mb-2 text-justify">
                <a :href="getBlobsFile(file.announcement.announcement_attachment_file)"
                  target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
              </small>
              
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button class="bg-primary border-0" @click="doAdd()"> Buat Pengumuman </base-button>
            <base-button class="bg-danger border-0" @click="showFormModal = false">Batal</base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Modal untuk View -->
    <Modal
      :show="showFormModalView"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showFormModalView = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <h3>Pengumuman Detail</h3>
          <b-row>
            <b-col xl="12">
              <div class="container">
                <b-card>
                  <template v-slot:header>
                    <div class="card-body">
                      <!-- <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Nama</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>{{ updateTitle }}</span>
                        </div>
                      </div> -->

                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Publis Tanggal</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>{{ tanggalUpdate }}</span>
                        </div>
                      </div>
              
                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Pengumuman</strong>
                        </div>
                        <div class="col-lg-10">
                          <span v-html="updateContent"> </span>
                        </div>
                      </div>

                      <div class="row mb-4" v-if="updateFile">
                        <div class="col-lg-2">
                          <strong>Lampiran</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>
                            <a :href="getBlobsFile(updateFile)" target="blank" class="text-blue">Lihat Lampiran</a>
                          </span>
                        </div>
                      </div>

                      
                    </div>
                  </template>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showFormModalView = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

     <!-- Modal untuk Edit pengumuman table -->
     <Modal
      :show="showFormModalUpdate"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showFormModalUpdate = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div>
            <div class="p-3">
              <h2 class="text-center">Merubah Pengumuman</h2>

              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Tanggal</label>
                <div class="col-lg-12">
                  <base-input class="m-0">
                      <flat-picker
                          placeholder="Tanggal"
                          :config="provideSelect.flatPickerConfig"
                          class="form-control datepicker"
                          v-model="tanggalUpdate"
                          style="background:white;"
                      />
                  </base-input>
                </div>
              </div>

              <!-- <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Title</label>
                <div class="col-lg-12">
                  <base-input
                    name="Title"
                    rules="required"
                    v-model="updateTitle"
                  ></base-input>
                </div>
              </div> -->

              <label class="form-control-label">Isi Pengumuman</label>
              <html-editor v-model="updateContent"></html-editor>

              <base-input
                  rules="required"
                  name="File"
              >
                  <file-input
                  accept="application/pdf"
                  ref="announcement_attachment_file_update"
                  @change="uploadFile('announcement_attachment_file_update', 'announcement_attachment_file_update')"
                  ></file-input>
              </base-input>
                <a :href="getBlobsFile(updateFile)" v-if="updateFile" target="blank" class="mt-2 text-blue">Lihat File Terupload</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button class="bg-secondary border-0" @click="doEdit()"> Ubah Data </base-button>
            <base-button class="bg-danger border-0" @click="showFormModalUpdate = false">Batal</base-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import BaseHeader from '@/components/BaseHeader';
  import API from "@/api/base_url.js";
  import HtmlEditor from "@/components/Inputs/HtmlEditor";
  import Modal from "@/components/Modal.vue";
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import FileInput from "@/components/Inputs/FileInput";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import { Select, Option, Table, TableColumn } from "element-ui";
  import moment from 'moment'
  import baseURL from "@/api/base_url_backend.js";

  export default {
    components: {
    FileInput,
    Modal,
    flatPicker,
    BaseHeader,
    HtmlEditor,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    },
    mounted() {
      this.getData()
    },
    data() {
      return {
        file: {
          announcement: {
            announcement_attachment_file: ''
          }
        },
        pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
        },
       isLoading:true,
       showFormModal: false,
       showFormModalView: false,
       showFormModalUpdate: false,
       listData: [],
       tanggal:"",
       tanggalUpdate:"",
       note:"",
       addTitle:"",
       addContent:"",
       idWillUpdate:"",
       updateTitle:"",
       updateContent:"",
       updateFile:"",
       formData: new FormData(),
       provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altFormat: "Y-m-d",
          altInput: false,
          dateFormat: "Y-m-d",
          mode: "single",
        },
    },
      };
    },
    methods: {
      // start upload
      uploadFileBlob(form, field) {
        const fileInput = this.$refs[field];
        const file = fileInput.files[0];

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
        if (!allowedTypes.includes(file.type)) {
          Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
          return;
        }

        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        this.isLoading = true;
        API.post(`blobs/upload/public`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            let data = res.data.data;
            let value = data.id;
            this.$set(form, field, value);
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
          });
      },
      getBlobsFile(filename) {
        let url = `${baseURL}blobs/download/${filename}`
        return url
      },
      resetFileInput(ref) {
      if (this.$refs[ref]) {
        // this.$refs.lulus_uji_file_id.files = []
        this.$refs[ref].files = []
        const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
        inputElement.value = ''
      }
      },
      resetAllFileInputs(refs) {
        refs.forEach(ref => this.resetFileInput(ref));
      },
      // end of upload
      formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
      },
      handleEdit(row) {
        this.showFormModalUpdate = true
        this.updateTitle = row.title
        this.updateContent = row.content
        this.updateFile = row.attachment_file_id
        this.tanggalUpdate = row.publish_date
        this.idWillUpdate = row.id
      },
      view(row) {
        this.showFormModalView = true
        this.updateTitle = row.title
        this.updateContent = row.content
        this.updateFile = row.attachment_file_id
        this.tanggalUpdate = row.publish_date
        this.idWillUpdate = row.id
      },
      changeSizePage() {
        this.getData()
      },
      paginationChanged(page) {
        this.pagination.currentPage = page;
        this.getData()
      },
      addAnouncement() {
        console.log('add Announcement');
        this.showFormModal = true
      },
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`announcements?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
          .then(({data}) => {
              if (data.data == null) {
                this.listData = []
              } else {
                this.listData = data.data
                this.pagination.total = data.meta.pagination.total_items
              }
              this.isLoading = false
          })
          .catch((err) => {
            Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
            this.isLoading = false
        })
      },
      doAdd() {
        if (!this.tanggal || !this.addContent) {
        Swal.fire('Data anda tidak lengkap!','Wajib mengisi semua data.','error')
      } else {
        Swal.fire({
          title: 'Tambah Pengumuman?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Kirim',
          denyButtonText: `Batal`,
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
           


            let payload = {
              attachment_file_id: this.file.announcement.announcement_attachment_file,
              content: this.addContent,
              publish_date: this.tanggal
            }
  
            API.post('announcements', payload, {headers})
              .then(({data}) => {
                Swal.fire('Saved!', '', 'success')
                this.addTitle = ""
                this.addContent = ""
                this.tanggal = ""

                this.getData()
                this.showFormModal = false
              })
              .catch((err) => {
                Swal.fire('Gagal Membuat Pengumuman', '', 'errror')
              })
  
  
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        })
      }
      },
      doEdit() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
      }
  
      let payload = {
        attachment_file_id: "36f8ded9-a759-410b-af79-3055fcf25795",
        content: this.updateContent,
        publish_date: this.tanggalUpdate
      }

        Swal.fire({
        title: 'Merubah Data ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ubah',
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.isConfirmed) {
          API.put(`announcements/${this.idWillUpdate}` , payload, {headers})
          .then(({data:content}) => {
            Swal.fire('Data berhasil diubah!','','success')

            this.getData()
            this.updateTitle = ""
            this.updateContent = ""
            this.updateFile = ""
            this.tanggalUpdate = ""
            this.idWillUpdate = ""

            this.showFormModalUpdate = false
          })
          .catch((err) => {
            Swal.fire('Data gagal diEdit!','','error')
        })
        }
      })
      },
      handleDelete(row) {
      Swal
        .fire({
          title: "Hapus Data ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Hapus",
          cancelButtonText: "Batal",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          API.delete(`announcements/${row.id}` , {headers})
          .then(({data:content}) => {
            this.getData()
            Swal.fire('Berhasil Menghapus','','success')
          })
          .catch((err) => {
            Swal.fire('Gagal Menghapus','','error')
            console.log(err);
          })
          }
        });
      },
    },
  };
</script>
<style></style>
